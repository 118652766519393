// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// Icons
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/lawnPesticides.webp)`,
    backgroundSize: 'cover',
    height: 600,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 80,
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  mainDescription: {
    display: 'flex',
    justifyContent: 'center',
  },
  mainDescriptionText: {
    width: '70%',
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  ctaWrapper: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  primaryCta: {
    [theme.breakpoints.up(870)]: {
      width: '49.99%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 400,
    maxWidth: 600,
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  productName: {
    fontWeight: 600,
  },
  productPrice: {
    fontWeight: 600,
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  diagramImg: {
    width: '100%',
    maxWidth: 500,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  sampleReportImg: {
    width: '100%',
    maxWidth: 700,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  bioTestPropertiesList: {
    listStyle: 'disc',
    listStylePosition: 'inside',
  },
  offeringsTable: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '1000px',
  },
  offeringsTableRow: {
    borderTop: '1px solid lightgray',
  },
  priceColumn: {
    margin: '0',
    padding: '0',
  },
}));

const Pesticides = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Pesticide Testing',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/pesticides`,
    page_path: '/pesticides',
  });

  if (window.localStorage && window.localStorage.getItem('source') === null && typeof URLSearchParams !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    let sourceToken = query.get('source');
    if (sourceToken === null) {
      sourceToken = 'direct';
    }
    window.localStorage.setItem('source', sourceToken);
  }

  function createData(service, coverage, price, link) {
    return {
      service, coverage, price, link,
    };
  }

  const rows = [
    createData('Herbicide Screen', '2,4-D, Clopyralid, Dicamba, MCPA, Picloram, MCPP, and Quinclorac', 279.95, 241),
    createData('Insecticide Screen', 'Bifenthriin, Cypermethrin, Cyhalothrin, Cyfluthrin, and Imidacloprid', 229.95, 243),
    createData('Fungicide Screen', 'Tebuconozole, Metalaxyl, and Azoxystrobin', 229.95, 244),
    createData('Glyphosate Screen (Roundup®)', 'Glyphosate', 239.95, 242),
    createData('Herbicide Package Screen', 'Glyphosate, 2,4-D, Clopyralid, Dicamba, MCPA, Picloram, MCPP, Quinclorac', '469.95 (10% Off)', 245),
  ];

  const handleBuyPesticide = (which) => {
    gtag('event', 'conversion', {
      event_category: `Conversion - Buy ${which} Button Clicked`,
      event_label: `Conversion - Buy ${which} Button Clicked`,
      value: `Conversion - Buy ${which} Button Clicked`,
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 229.95,
      items: [{
        id: 'RX005',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/${which}-1?source=${window.localStorage.getItem('source')}`);
  };

  return (
    <>
      <CssBaseline />
      <Header current="Pesticides" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              Test for Pesticides in Your Soil
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              With the prevalent use of presticides in our modern society, these substances
              can find their way into our lawns, gardens, and homes. Rx Soil provides
              resources and laboratory mail-in testing for a wide array of pesticides, to give
              you the confidence that these common chemicals are not in your yard or growing spaces.
            </Typography>
          </Box>
        </Container>
      </div>

      <Container className={classes.blurbs} maxWidth="lg">
        <div className={classes.blurb}>
          <img
            alt="Laboratory with scientists"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/labIcon.webp`}
            title="Microbiome"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Accredited Laboratory Analysis
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Rx Soil partners with the national leader in testing and certification,
            and has created pesticide tests specifically for the residential homeowner.
            With an ISO accredited lab, have added confidence in the precision and accuracy
            of your pesticide analysis.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="System with gears and computer"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/systemIcon.webp`}
            title="Knowledge"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Resources and Insight
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            An Rx Soil pesticide report tells you not only the results of each substance tested,
            but also gives information about how the pesticide works and some of the most common
            related brand names, to assist with your research into your soil health.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Test kit box moving quickly on back of truck"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/fastShipping.webp`}
            title="Shipping"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Free Shipping
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            All the sampling materials, packaging, and shipping costs are included in your price.
            Simply sample your soil, place the box in the mail, and wait for your results. Get your
            pesticide report right from the comfort of your home in a few weeks.
          </Typography>
        </div>
      </Container>

      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          What Are The Types Of Pesticides And What Tests Do We Offer?
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            Pesticide is an umbrella term for any substance used to prevent, destroy, repel, or
            mitigate a pest. For homeowners in a residential setting, weeds, insects, and funguses
            are some of the more common targets for pest control:
            <img
              alt="Diagram showing different classes of pesticides"
              height="375"
              width="500"
              className={classes.diagramImg}
              src={`${process.env.PUBLIC_URL}/images/pesticidesDiagram.webp`}
              loading="lazy"
            />
            With more than 17,000 pesticides products on the market, and over a billion pounds
            applied annually, testing options can be overwhelming. Working with our
            laboratory partners, Rx Soil has curated a list of pesticide services that detect
            the types of common pesticides most likely to impact the residential homeowner.
          </Typography>
        </div>
        <br />
        <br />
        <Typography variant="h6" align="center" gutterBottom>
          Rx Soil Pesticide Detection Services
        </Typography>
        <TableContainer className={classes.offeringsTable} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Service Name</TableCell>
                <TableCell align="left"> </TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="left">Substances Covered</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  className={classes.offeringsTableRow}
                  key={row.service}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" size="small">
                    {row.service}
                  </TableCell>
                  <TableCell className={classes.priceColumn} align="left" size="small">
                    <Button
                      className={classes.buyButton}
                      variant="contained"
                      color="primary"
                      onClick={() => handleBuyPesticide(row.link)}
                    >
                      PURCHASE
                    </Button>
                  </TableCell>
                  <TableCell className={classes.priceColumn} align="left" size="small">
                    $
                    {row.price}
                  </TableCell>
                  <TableCell align="left" size="medium">{row.coverage}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <br />
        <br />
        <br />
        <Typography variant="h6" align="center" gutterBottom>
          What Will Your Report Look Like When Completed?
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            Your report will tell you whether or not each pesticide was detected in your soil,
            as well as an information breakdown of how the pesticide works, with a listing of
            some of the popular brand names the pesticide is sold under.
            <br />
            <br />
            A partial example of the data presented in one of our reports:
          </Typography>
        </div>
        <img
          alt="Pesticide report sample showing some insecticides detected and others not"
          height="1280"
          width="840"
          className={classes.sampleReportImg}
          src={`${process.env.PUBLIC_URL}/images/pesticideReport.webp`}
          loading="lazy"
        />
      </div>
      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          Ready to get started?
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            Purchase one of our pesticide tests above, or contact us with
            any questions.
          </Typography>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default Pesticides;
