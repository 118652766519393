// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
// Icons
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/packageDealBanner.webp)`,
    backgroundSize: 'cover',
    height: 600,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'right',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 80,
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  mainDescription: {
    display: 'flex',
    justifyContent: 'center',
  },
  mainDescriptionText: {
    width: '70%',
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  ctaWrapper: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  primaryCta: {
    [theme.breakpoints.up(870)]: {
      width: '49.99%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 400,
    maxWidth: 600,
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  productName: {
    fontWeight: 600,
  },
  productPrice: {
    fontWeight: 600,
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  diagramImg: {
    width: '100%',
    maxWidth: 500,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  sampleReportImg: {
    width: '100%',
    maxWidth: 700,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  completePropertiesList: {
    listStyle: 'disc',
    listStylePosition: 'inside',
  },
  offeringsTable: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '1000px',
  },
  offeringsTableRow: {
    borderTop: '1px solid lightgray',
  },
  priceColumn: {
    margin: '0',
    padding: '0',
  },
}));

const Packages = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Package Deals',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/packages`,
    page_path: '/packages',
  });

  if (window.localStorage && window.localStorage.getItem('source') === null && typeof URLSearchParams !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    let sourceToken = query.get('source');
    if (sourceToken === null) {
      sourceToken = 'direct';
    }
    window.localStorage.setItem('source', sourceToken);
  }

  function createData(service, price) {
    return {
      service, price,
    };
  }

  const rows = [
    createData('Herbicide Screen', '$279.95'),
    createData('Glyphosate Screen', '$239.95'),
    createData('Heavy Metals RCRA8 Panel', '$199.95'),
    createData('Soil Nutrient Test', '$39.95'),
    createData(' ', ' '),
    createData('Total Price Without Savings', '$759.80'),
  ];

  const handleBuyHealthPackage = (which) => {
    gtag('event', 'conversion', {
      event_category: `Conversion - Buy ${which} Button Clicked`,
      event_label: `Conversion - Buy ${which} Button Clicked`,
      value: `Conversion - Buy ${which} Button Clicked`,
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 229.95,
      items: [{
        id: 'RX006',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/246-1?source=${window.localStorage.getItem('source')}`);
  };

  return (
    <>
      <CssBaseline />
      <Header current="Package Deals" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              Bundle Services, Save Money
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              For those interested in both soil nutrient analysis and contaminant detection
              services, bundling our individual tests together will let you track all your
              results in one place and save more money over buying individually.
            </Typography>
          </Box>
        </Container>
      </div>

      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          Complete Soil Care Package
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            The Complete Soil Care Package is the flagship offering for all three major Rx Soil test
            types. It includes a soil nutrient analysis test, the full RCRA8 metals panel, and
            a complete herbicide screen, including Glyphosate.
            <br />
            <br />
            In total, this package includes:
            <br />
            <br />
            <ul className={classes.completePropertiesList}>
              <li>Soil Nutrient Test: Shipping Box and Collection Bag</li>
              <li>Shipping to You</li>
              <li>Shipping to the Lab</li>
              <li>pH</li>
              <li>Organic Matter</li>
              <li>Capacity to Retain Nutrients</li>
              <li>Phosphorus</li>
              <li>Potassium</li>
              <li>Calcium</li>
              <li>Magnesium</li>
              <li>Sulfur</li>
              <li>Zinc</li>
              <li>Manganese</li>
              <li>Iron</li>
              <li>Copper</li>
              <li>Boron</li>
              <li>12 Month Fertilization Recommendations</li>
              <li>Customized Application Weights</li>
              <br />
              <li>Heavy Metals Test: Shipping Box and Collection Bag</li>
              <li>Shipping to You</li>
              <li>Shipping to the Lab</li>
              <li>Heavy Metals PPM: Arsenic</li>
              <li>Heavy Metals PPM: Barium</li>
              <li>Heavy Metals PPM: Cadmium</li>
              <li>Heavy Metals PPM: Chromium</li>
              <li>Heavy Metals PPM: Lead</li>
              <li>Heavy Metals PPM: Mercury</li>
              <li>Heavy Metals PPM: Selenium</li>
              <li>Heavy Metals PPM: Silver</li>
              <br />
              <li>Herbicide Detection Test: Shipping Box and Collection Bag</li>
              <li>Shipping to You</li>
              <li>Shipping to the Lab</li>
              <li>Herbicide Detection: Glyphosate</li>
              <li>Herbicide Detection: 2,4-D</li>
              <li>Herbicide Detection: Clopyralid</li>
              <li>Herbicide Detection: Dicamba</li>
              <li>Herbicide Detection: MCPA</li>
              <li>Herbicide Detection: Picloram</li>
              <li>Herbicide Detection: MCPP</li>
              <li>Herbicide Detection: Quinclorac</li>
            </ul>
          </Typography>
        </div>
        <br />
        <br />
        <Typography variant="h6" align="center" gutterBottom>
          Savings Versus Purchasing Individually
        </Typography>
        <TableContainer className={classes.offeringsTable} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Service Name</TableCell>
                <TableCell align="left">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  className={classes.offeringsTableRow}
                  key={row.service}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" size="small">
                    {row.service}
                  </TableCell>
                  <TableCell className={classes.priceColumn} align="left" size="small">
                    {row.price}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <br />
        <br />
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.section}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item style={{ margin: '0 auto' }}>
                  <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                    Complete Soil Care Package
                  </Typography>
                  <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                    $679.95 (10% Savings)
                  </Typography>
                  <div className={classes.checkWrapper}>
                    Do you agree to our terms and conditions?
                    <Checkbox className={classes.confirmChecked} />
                  </div>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyHealthPackage('Complete Soil Care')}
                  >
                    BUY NOW
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className={(classes.section)}>
              <img
                alt="Soil analysis kit"
                height="286"
                width="420"
                className={classes.boxImg}
                src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </Container>
      </div>
      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          Ready to get started?
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            Purchase our Complete Soil Care Package now, or contact us with
            any questions.
          </Typography>
        </div>
      </div>
      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          Interested in Bulk Pricing?
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            We are happy to help with customized quotes for bulk pricing on any
            of our test offerings. Call or contact us today for custom quotes on
            multiple test kits.
          </Typography>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default Packages;
