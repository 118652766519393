// React Core
import React from 'react';
import { Helmet } from 'react-helmet';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Carousel from 'react-material-ui-carousel';
// MUI Icons and Font Awesome
// Adapify
import gtag from '../Utilities/Gtag';
import YoutubeEmbed from '../Embeds/YoutubeEmbed';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0, 6),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/B2CBanner.webp)`,
    backgroundPositionY: '100%',
    backgroundPositionX: '100%',
    backgroundSize: 'cover',
    height: 420,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 0,
  },
  heroBox: {
    display: 'table',
    position: 'relative',
    textAlign: 'left',
    height: '100%',
    margin: 'auto 40px',
    maxWidth: '70%',
    zIndex: 1,
    [theme.breakpoints.down(900)]: {
      maxWidth: '100%',
      '& h1': {
        textAlign: 'center',
      },
    },
    [theme.breakpoints.down(500)]: {
      margin: 'auto 20px',
    },
  },
  textHeader: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'left',
    color: '#fff',
    fontWeight: 600,
    fontSize: 48,
    [theme.breakpoints.down(500)]: {
      fontSize: 28,
    },
  },
  ctaForm: {
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      minWidth: 582,
    },
  },
  cta2Form: {
    padding: 16,
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      padding: 0,
      minWidth: 582,
    },
  },
  videoSection: {
    marginTop: 50,
  },
  videoWrapper: {
    marginTop: 60,
  },
  video: {
    borderRadius: 4,
    width: 560,
    height: 315,
    [theme.breakpoints.up(901)]: {
      width: 650,
      height: 365,
    },
    [theme.breakpoints.down(651)]: {
      width: 420,
      height: 234,
    },
    [theme.breakpoints.down(500)]: {
      width: 300,
      height: 167,
    },
  },
  secondCta: {
    marginTop: 80,
    marginBottom: 100,
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: '40px 0',
      padding: 15,
      width: '100%',
    },
  },
  sectionIcon: {
    margin: '0 20px',
    width: '30%',
    [theme.breakpoints.down(900)]: {
      margin: '0 auto',
      '& img': {
        width: 75,
        display: 'block',
        height: 'auto',
      },
    },
    '& img': {
      maxWidth: 200,
    },
  },
  sectionIconMob: {
    display: 'none',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      display: 'block',
      width: '100%',
      '& img': {
        width: 75,
        display: 'block',
        height: 'auto',
      },
    },
    '& img': {
      display: 'block',
      margin: '0 auto',
      maxWidth: 200,
    },
  },
  sectionIconDesk: {
    display: 'block',
    margin: '0 20px',
    width: '30%',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    },
    '& img': {
      maxWidth: 200,
    },
  },
  sectionText: {
    margin: '0 20px',
    width: '70%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  mainAlert: {
    '& .MuiAlertTitle-root': {
      fontWeight: 600,
      fontSize: 24,
      color: '#fff',
    },
    '& .MuiSvgIcon-root': {
      fill: '#fff',
      width: 30,
      height: 30,
    },
    backgroundColor: '#7ab0de',
    width: '80%',
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: 50,
    fontSize: 20,
    fontWeight: 500,
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  videoBlurbs: {
    [theme.breakpoints.up(980)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  videoBlurb: {
    [theme.breakpoints.up(980)]: {
      width: '50%',
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
  },
  boxImg: {
    height: 'auto',
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
    [theme.breakpoints.up(501)]: {
      width: 420,
    },
  },
  carouselServiceTitle: {
    color: theme.palette.primary.main,
  },
  carouselImg: {
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
    [theme.breakpoints.up(501)]: {
      width: 420,
    },
  },
  carouselRegion: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselBox: {
    border: '1px solid lightgray',
    maxWidth: '600px',
    margin: 'auto',
    padding: '15px',
  },
  carouselInfoButton: {
    fontWeight: 600,
    margin: '0 auto',
    width: '300px',
    height: '35px',
    display: 'block',
  },
  productName: {
    fontWeight: 600,
    fontStyle: 'italic',
    textAlign: 'left',
    display: 'inline-block',
    '& span': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down(840)]: {
      textAlign: 'center',
    },
  },
  productPrice: {
    fontWeight: 600,
    [theme.breakpoints.down(840)]: {
      textAlign: 'center',
    },
  },
  buyButton: {
    fontWeight: 600,
    [theme.breakpoints.down(840)]: {
      marginLeft: '23%',
      marginRight: '21%',
    },
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  main: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    marginTop: 70,
    marginBottom: 40,
    padding: 80,
    '& .MuiGrid-root.MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    [theme.breakpoints.down(510)]: {
      padding: 10,
      '& h4': {
        marginTop: 40,
        marginBottom: 40,
        fontSize: 20,
      },
    },
  },
  analysis: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  videoHeader: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  bottomCTA: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  mainSection: {
    height: 440,
    width: 'auto',
    margin: 40,
    padding: 20,
    [theme.breakpoints.down(900)]: {
      height: 'auto',
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  instructionsImg: {
    width: '100%',
    maxWidth: 840,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  recommendationsImg: {
    width: '100%',
    maxWidth: 550,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  bagImg: {
    width: 'auto',
    height: '100%',
    maxHeight: 400,
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(840)]: {
      maxWidth: 360,
      width: '100%',
      height: 'auto',
    },
  },
  shipImg: {
    width: 'auto',
    height: '100%',
    maxHeight: 340,
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(840)]: {
      maxWidth: 480,
      width: '100%',
      height: 'auto',
    },
  },
  fabContainer: {
    zIndex: 1000,
    width: 350,
    height: 500,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fullServicesText: {
    fontWeight: '450',
    fontSize: '18px',
    padding: '20px 20px',
  },
  videoResponsive: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: '0',
    '& iframe': {
      left: '0',
      top: '0',
      height: '100%',
      width: '100%',
      position: 'absolute',
    },
  },
}));

const Website = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Home',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/`,
    page_path: '/',
  });

  const offeringsCarouselData = [
    {
      serviceName: 'Soil Nutrient Testing',
      serviceDescription: 'Perfect for lawn and garden, price includes laboratory analysis of 13 soil properties AND a 12-month nutrient management plan',
      imageSource: `${process.env.PUBLIC_URL}/images/soil-analysis.webp`,
      imageAltText: 'Soil analysis results sample data',
      moreInfoHref: '/nutrients',
      linkText: 'Nutrient Testing',
    },
    {
      serviceName: 'Heavy Metals Testing',
      serviceDescription: 'Discover the levels of lead and other heavy metals in your soil, with information and resources to learn more about what those levels mean',
      imageSource: `${process.env.PUBLIC_URL}/images/metalReport.webp`,
      imageAltText: 'Metals sample data showing lead and mercury',
      moreInfoHref: '/metals',
      linkText: 'Metals Testing',
    },
    {
      serviceName: 'Soil Pesticide Testing',
      serviceDescription: 'Check your soil for the presence of pesticides, testing for the most common residential herbicides, insecticides, and fungicides',
      imageSource: `${process.env.PUBLIC_URL}/images/pesticideReport.webp`,
      imageAltText: 'Pesticide sample data showing four herbicides',
      moreInfoHref: '/pesticides',
      linkText: 'Pesticide Testing',
    },
  ];

  if (window.localStorage && window.localStorage.getItem('source') === null && typeof URLSearchParams !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    let sourceToken = query.get('source');
    if (sourceToken === null) {
      sourceToken = 'direct';
    }
    window.localStorage.setItem('source', sourceToken);
  }

  return (
    <>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Test and improve the soil health of your lawn or garden. Review an in-depth analysis of your soil. Know what products to put down, when, and how much." />
        <meta name="keywords" content="soil testing,soil test,soil analysis,home soil test,soil health,soil testing online,mail in soil test,garden soil test,lawn soil test" />
        <meta name="robots" content="index, follow" />
        <title>Soil Testing - Home Soil Analysis Kits | Rx Soil</title>
        <link rel="canonical" href="https://rxsoil.com" />
      </Helmet>

      <Header current="home" />
      {/* Hero unit */}
      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item style={{ margin: '0 auto' }}>
                <Typography className={classes.productName} variant="h3" color="textPrimary" gutterBottom>
                  <span>Know </span>
                  and
                  <br />
                  <span>improve </span>
                  your
                  <br />
                  soil health.
                </Typography>
                <br />
                <div className={classes.checkWrapper}>
                  Do you agree to our terms and conditions?
                  <Checkbox className={classes.confirmChecked} />
                </div>
                <Button
                  className={classes.buyButton}
                  variant="contained"
                  color="primary"
                  href="/nutrients"
                >
                  Nutrient Testing
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={(classes.section)}>
            <img
              alt="Soil analysis kit"
              height="286"
              width="420"
              className={classes.boxImg}
              src={`${process.env.PUBLIC_URL}/images/kitcircle.webp`}
              loading="lazy"
            />
          </div>
        </div>
      </Container>
      {/* End hero unit */}

      <Container className={classes.blurbs} maxWidth="lg">
        <div className={classes.blurb}>
          <img
            alt="Flowers in laboratory glass"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/science.webp`}
            title="Fast"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            A Complete Soil Testing Company
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Whether you are looking for a healthier lawn or garden, investigating contaminants
            in your soil, or just want to save money with smarter fertilization, Rx Soil
            provides a variety of soil testing services.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Land grid with data markers"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/growingArea.webp`}
            title="Save"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Your Partner For The Future
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Use Rx Soil to keep track of your soil test results from one year to the next,
            making it easy to compare your soil&apos;s progress and treatments across your home
            and property.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Planet earth"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/earth.webp`}
            title="Environment"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Test Offerings For All
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            From testing soil in your home lawn or garden, to working at scale with
            businesses and landscapers, Rx Soil provides laboratory services directly to
            you, quickly, at competitive prices.
          </Typography>
        </div>
      </Container>

      <Alert className={classes.mainAlert} severity="info">
        <strong>Spring 2023: </strong>
        <br />
        <br />
        We&apos;re happy to announce that free two-way shipping will be included in the price
        for all our test kits again in 2023, both to get the test to you and to get the
        soil to the lab!
        <br />
      </Alert>

      <br />
      <br />

      <div className={classes.analysis}>
        <Typography variant="h4" align="center" gutterBottom>
          Learn More About Our Services:
        </Typography>
        <br />
        <br />
        <Carousel navButtonsAlwaysVisible interval="7500">
          {
            offeringsCarouselData.map((item) => (
              <div className={classes.carouselBox}>
                <Typography className={classes.carouselServiceTitle} variant="h6" component="h6" align="center" gutterBottom>
                  {item.serviceName}
                </Typography>
                <Typography variant="subtitle2" component="p" align="center" paragraph>
                  {item.serviceDescription}
                </Typography>
                <img
                  alt={item.imageAltText}
                  height="420"
                  width="420"
                  className={classes.carouselImg}
                  src={item.imageSource}
                  loading="lazy"
                />
                <br />
                <Button
                  className={classes.carouselInfoButton}
                  variant="contained"
                  color="primary"
                  href={item.moreInfoHref}
                >
                  <Typography variant="subtitle2" component="p" align="center" paragraph>
                    {item.linkText}
                  </Typography>
                </Button>
              </div>
            ))
          }
        </Carousel>
        <br />
      </div>
      <br />
      <br />
      <div className={classes.videoHeader}>
        <Typography variant="h4" align="center" gutterBottom>
          See Rx Soil in Action:
        </Typography>
        <Container className={classes.videoBlurbs} maxWidth="lg">
          <div className={classes.videoBlurb}>
            <h3>Ryan Knorr Lawn Care</h3>
            <YoutubeEmbed embedId="1SYku44Gv_s" />
          </div>
          <div className={classes.videoBlurb}>
            <h3>Ben DeCastro</h3>
            <YoutubeEmbed embedId="cm3Ivacq2vY" />
          </div>
        </Container>
        <Container className={classes.videoBlurbs} maxWidth="lg">
          <div className={classes.videoBlurb}>
            <h3>GirlzLawn</h3>
            <YoutubeEmbed embedId="RYXaJl78MGo" />
          </div>
          <div className={classes.videoBlurb}>
            <h3>DIY with Cam</h3>
            <YoutubeEmbed embedId="A1Blj5kb2HE" />
          </div>
        </Container>
        <br />
      </div>
      <br />
      <br />
      <div className={classes.bottomCTA}>
        <Typography variant="h5" align="center" gutterBottom>
          Not sure where to get started? Our Soil Nutrient Analysis is the perfect starter kit for
          lawn and garden.
        </Typography>
      </div>
      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item style={{ margin: '0 auto' }}>
                <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Nutrient Analysis Kit
                </Typography>
                <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                  $39.95
                </Typography>
                <div className={classes.checkWrapper}>
                  Do you agree to our terms and conditions?
                  <Checkbox className={classes.confirmChecked} />
                </div>
                <Button
                  className={classes.buyButton}
                  variant="contained"
                  color="primary"
                  href="/nutrients"
                >
                  Nutrient Testing
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={(classes.section)}>
            <img
              alt="Soil analysis kit"
              height="286"
              width="420"
              className={classes.boxImg}
              src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
              loading="lazy"
            />
          </div>
        </div>
      </Container>
      <div className={classes.bottomCTA}>
        <Typography className={classes.fullServicesText} variant="subtitle2" component="p" align="center" paragraph>
          If you have any soil testing needs that you
          do not see listed, please get in touch with us!
        </Typography>
      </div>
      <Footer />
    </>
  );
};

export default Website;
